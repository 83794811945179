<template>
  <div id="content">
    <div id="loading" v-if="loading">
      <div class="dot-flashing"></div>
    </div>
    <div class="wrapper">
      <header>
        <h1>{{ $t('message.admin_collaborators') }}</h1>
      </header>

      <div id="email_form">
        <v-alert
          v-model="alertSuccess"
          text
          dense
          color="teal"
          border="left"
          type="success"
          dismissible
        >
          {{ successMessage }}
        </v-alert>

        <p v-if="errors.length">
          <v-alert
            v-model="alertError"
            text
            dense
            type="error"
            dismissible
            v-for="(error, index) in errors"
            :key="index"
          >
            {{ error.title }}
          </v-alert>
        </p>
      </div>

      <div class="clear"></div>

      <div v-if="userClearances.length === 0 && loading === false" class="subtitle-bread text-center mt-15">
        {{ $t('message.no_collaborator') }} <a :href='getCollaboratorUrl()' target="_blank">{{ $t('message.your_fiducial_account') }}</a>
      </div>

      <div class="mb-4">
        <table class="mdl-data-table mdl-js-data-table mdl-data-table--selectable mdl-table-custom sm-2" v-if="userClearances.length">
          <thead>
          <tr>
            <th>{{ $t('message.collaborator') }}</th>
            <th
              v-for="(section, sectionIndex) in sections"
              :key="sectionIndex"
            >
              {{ section.sectionName }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="(userClearance, userIndex) in userClearances"
            :key="userIndex"
          >
            <td data-label="Nom :">
              <p class="collaborator">
                <span class="font-weight-bold">{{ userClearance.firstName }} {{ userClearance.lastName }}</span>
                <br>
                <span class="small">{{ $t('message.email') }} : {{ userClearance.email ? userClearance.email : '-' }}</span><br>
                <span class="small">{{ $t('message.created_at') }} : {{ userClearance.createdAt ? userClearance.createdAt : '-' }}</span><br>
                <span class="small">{{ $t('message.last_login') }} : {{ userClearance.lastLoginAt ? userClearance.lastLoginAt : '-' }}</span>
              </p>
            </td>
            <td
              v-for="(section, sectionIndex) in userClearance.sections"
              :key="sectionIndex"
            >
              <v-switch v-model="section.hasClearance" v-on:change="handleUserClearance(userClearance, section)"></v-switch>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import DefaultLayout from '../../layouts/DefaultLayout.vue'
import config from '../../config'

export default {
  name: 'Clearances',
  data: () => ({
    alertSuccess: false,
    alertError: false,
    successMessage: null,
    errors: [],
    loading: false,
    userClearances: [],
    sections: []
  }),
  created () {
    this.$emit('update:layout', DefaultLayout)
    this.getUserClearances()
    document.title = config.title + ' - ' + this.$t('message.admin_collaborators')
  },
  computed: {
    currentCompany () {
      return this.$store.getters.getCurrentCompany
    },
    currentVault () {
      return this.$store.getters.getCurrentVault
    }
  },
  methods: {
    getUserClearances () {
      this.loading = true

      const params = {
        company: this.currentCompany.id
      }

      Vue.prototype.$http
        .get(config.apiUrl + '/user-clearances', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: params
        })
        .then(response => {
          if (response.status === 200) {
            const userClearances = response.data

            if (userClearances && userClearances.length) {
              this.userClearances = userClearances

              this.sections = userClearances[0].sections
            }
          }

          this.loading = false
        })
        .catch(e => {
          this.loading = false
        })
    },
    handleUserClearance (userClearance, section) {
      if (section.hasClearance) {
        this.postClearance(userClearance, section)
      } else {
        this.deleteClearance(userClearance, section)
      }
    },
    postClearance (userClearance, section) {
      const data = {
        userId: userClearance.id,
        vaultId: this.currentVault.id,
        sectionId: section.sectionId
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      const options = {
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }

      this.loading = true

      Vue.prototype.$http
        .post(config.apiUrl + '/clearances', formData, options)
        .then(response => {
          if (response.status === 200) {
            this.alertSuccess = true
            this.successMessage = this.$t('message.clearance_added')
            this.errors = []
            this.getUserClearances()
          }
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.loading = false
        })
    },
    deleteClearance (userClearance, section) {
      this.loading = true

      Vue.prototype.$http
        .delete(config.apiUrl + '/clearances/' + section.clearanceId, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 204) {
            this.alertSuccess = true
            this.successMessage = this.$t('message.clearance_revoked')
            this.errors = []
          }

          this.getUserClearances()
        })
        .catch(e => {
          this.loading = false

          const response = e.response

          if (response && (response.status === 400 || response.status === 404)) {
            const data = response.data
            this.errors = data.violations
            this.successMessage = null
          }

          if (response.status === 500) {
            this.errors.push({
              title: this.$t('message.error_clearance_deletion'),
            })
          }
        })
    },
    hasRole (role, company) {
      return this.$store.getters.hasRole(role, company)
    },
    getCollaboratorUrl () {
      return config.collaboratorUrl
    }
  }
}
</script>
